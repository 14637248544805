import Vue from 'vue'
import VueRouter from 'vue-router'
import homeView from '../views/HomeView.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: homeView,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/HomePageView.vue')
      }
    ]
  },
  // 漫影隐私协议
  {
    path: '/MYPrivacyAgreement',
    name: 'MYPrivacyAgreement',
    component: () => import('../views/PrivacyAgreement.vue')
  },
  // 漫影会员协议
  {
    path: '/MYMembershipAgreement',
    name: 'MYMembershipAgreement',
    component: () => import('../views/MembershipAgreement.vue')
  },
  // 随意记隐私协议
  {
    path: '/NotePrivacyAgreement',
    name: 'NotePrivacyAgreement',
    component: () => import('../views/NotePrivacyAgreement.vue')
  },
  // 随意记用户服务协议
  {
    path: '/NoteUserServiceAgreement',
    name: 'NoteUserServiceAgreement',
    component: () => import('../views/NoteUserServiceAgreement.vue')
  },
  // EP隐私协议
  {
    path: '/EPPrivacyAgreement',
    name: 'EPPrivacyAgreement',
    component: () => import('../views/EPPrivacyAgreement.vue')
  },
  // EP用户服务协议
  {
    path: '/EPUserServiceAgreement',
    name: 'EPUserServiceAgreement',
    component: () => import('../views/EPUserServiceAgreement.vue')
  },
  // 智趣答题隐私协议
  {
    path: '/question/agreement/privacy',
    name: 'QuestionPrivacyAgreement',
    component: () => import('../views/question/PrivacyAgreement.vue')
  },
  // 智趣答题隐私协议-B面
  {
    path: '/question/agreement/privacy/detail',
    name: 'QuestionPrivacyAgreementDetail',
    component: () => import('../views/question/PrivacyAgreementB.vue')
  },
  // 智趣答题用户协议
  {
    path: '/question/agreement/user',
    name: 'QuestionUserAgreement',
    component: () => import('../views/question/UserAgreement.vue')
  },
  // 智趣答题用户协议-市场
  {
    path: '/question/agreement/user/market',
    name: 'QuestionUserAgreementMarket',
    component: () => import('../views/question/UserAgreementMarket.vue')
  },
  // 随意记隐私协议-英文
  {
    path: '/note/agreement/privacy/en',
    name: 'NotePrivacyAgreementEn',
    component: () => import('../views/note/PrivacyAgreementEn.vue')
  },
  // 随意记用户服务协议-英文
  {
    path: '/note/agreement/user/en',
    name: 'NoteUserServiceAgreementEn',
    component: () => import('../views/note/UserServiceAgreementEn.vue')
  },
  // 输入法隐私协议-英文
  {
    path: '/keyboard/agreement/privacy/en',
    name: 'KeyboardPrivacyAgreementEn',
    component: () => import('../views/keyboard/PrivacyAgreementEn.vue')
  },
  // 输入法用户服务协议-英文
  {
    path: '/keyboard/agreement/user/en',
    name: 'KeyboardUserServiceAgreementEn',
    component: () => import('../views/keyboard/UserServiceAgreementEn.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  routes
  // scrollBehavior (to, from, savedPosition) {
  //   // 解决路由跳转后 会滚动到底部
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return { x: 0, y: 0 }
  //   }
  // }
})
// 路由守卫
export default router
